import BasePlugin from '../BasePlugin'

export default class ExportScenarioCognosByInvestmentBudgetCommand extends BasePlugin {
  async execute () {
    // this.context.$loading()
    let me = this
    let scenarioId = this.context.getModel().id
    let period = this.context.getModel().period
    let address = `${this.context.$config.api}/registryservice/plugins/execute/ExportScenarioInvestmentBudgetAsyncCommand`
    me.context.$loading()
    this.context.$http({
      method: 'post',
      url: address,
      responseType: 'arraybuffer',
      data: { 'scenario_ids': [scenarioId], 'period_id': parseInt(period) }
    }).then(function (response2) {
      me.context.$loading().close()
      me.openExportAttachment(response2.data, me, 'xlsx')
    }, (response) => { card.setLoading(false) })
  }
}
